import React, { useState, useEffect } from 'react';
import { Spin, Table, Select, Button, DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getOrgTransactionsAction } from '../store/expenseActions';
import { type TransactionResponse } from '../store/expenseTypes';

export default function OrganizationTransactionsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { orgTransactions }: any = useSelector<any>((state) => state?.expense);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (branch) {
      void getTransactions();
    }
  }, [branch]);

  const getTransactions = async (): Promise<void> => {
    setIsLoading(true);
    await getOrgTransactionsAction(dispatch, {
      branch,
      date: datePickerDate,
    });
    setIsLoading(false);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: TransactionResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_: any, record: TransactionResponse, index: number) => (
        <p>{record.description}</p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: TransactionResponse, index: number) => (
        <p style={{ color: record.status === 'In' ? 'green' : 'red' }}>
          {record.amount}
        </p>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: TransactionResponse, index: number) => (
        <p>{record.type}</p>
      ),
    },
    {
      title: 'Transaction Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: TransactionResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.transactionDate as string).format('DD-MM-YYYY')}</p>
      ),
    },
    {
      title: 'Created Time',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: TransactionResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.createdDate as string).format('hh:mm A')}</p>
      ),
    },
  ];

  const [datePickerDate, setDatePickerDate] = useState<string>(
    dayjs().toISOString()
  );

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    setDatePickerDate(dateString);
  };

  return (
    <div className="org_transactions__page">
      <Spin spinning={isLoading} fullscreen />

      <div style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
        <Select
          value={branch}
          onChange={(value: string): void => {
            setBranch(value);
          }}
          style={{ width: 311, height: 56 }}
          placeholder="Select branch"
          options={branchOptions}
          className="expense__input"
        />
        <DatePicker
          value={dayjs(datePickerDate)}
          className="loan__input"
          onChange={onChangeDate}
        />
        <Button onClick={getTransactions} className="search__button">
          <span>Search</span>
        </Button>
      </div>
      {Boolean(orgTransactions.length) && (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={orgTransactions}
            columns={columns}
            rowKey={(record: TransactionResponse) => record._id}
          />
        </div>
      )}
      {orgTransactions.length === 0 && (
        <div style={{ width: '100%' }}>
          <p>No transactions available for the branch</p>
        </div>
      )}
    </div>
  );
}
