/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useState } from 'react';
import {
  type Customer,
  type LoanDetails,
  type Loan,
} from '../store/customerTypes';
import { Table, Modal } from 'antd';
// import dayjs from 'dayjs';
// import { useNavigate } from 'react-router-dom';
import PlusFilledWhite from '../../../assets/icons/plus-filled-white.svg';
import LoanModal from '../LoanModal';
import PaymentModal from '../PaymentModal';
import ActionModal from '../ActionModal';
import PrintableContent from '../PrintableContent';
import LoanPrintableContent from '../../Loan/PrintableContent';
import { type Loan as PrintLoan } from '../../Loan/PrintableContent/printTypes';
// import { getLoanByLoanNumberAction } from '../../Loan/store/loanActions';
// import { type AppDispatch } from '../../../redux/store';
// import { useDispatch } from 'react-redux';

interface Props {
  loan?: LoanDetails;
  customer?: Customer;
  refetchCustomer: (customerId: string) => void;
}

export default function loanDetailsIndex({
  loan,
  customer,
  refetchCustomer,
}: Props): JSX.Element {
  // const navigate = useNavigate();
  // const dispatch = useDispatch<AppDispatch>();

  const [selectedLoan, setSelectedLoan] = useState<Loan>();
  const [selectedLoanTransactions, setSelectedLoanTransactions] =
    useState<any>();

  const [printSelectedLoan, setPrintSelectedLoan] = useState<PrintLoan>();

  const nonActiveLoanColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: Loan, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Loan No.',
      dataIndex: 'loanNumber',
      width: 46,
      render: (_: any, record: Loan) => (
        <p className="closed__loan__data">{record.loanNumber}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'actions',
      width: 120,
      render: (_: any, record: Loan) => (
        <p
          style={{
            color: '#DD1E2D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={async () => {
            setSelectedLoan(record);

            setSelectedLoanTransactions(record.transactions);
            showModal();
          }}
        >
          View Details
        </p>
      ),
    },
  ];

  const closedLoanColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: Loan, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Loan No.',
      dataIndex: 'loanNumber',
      width: 46,
      render: (_: any, record: Loan) => (
        <p className="closed__loan__data">{record.loanNumber}</p>
      ),
    },
    {
      title: 'Pledge No.',
      dataIndex: 'pledgeNumber',
      width: 46,
      render: (_: any, record: Loan) => (
        <p className="closed__loan__data">{record.pledgeNumber}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'actions',
      width: 120,
      render: (_: any, record: Loan) => (
        <p
          style={{
            color: '#DD1E2D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={async () => {
            setSelectedLoan(record);

            setSelectedLoanTransactions(record.transactions);
            showModal();
          }}
        >
          View Details
        </p>
      ),
    },
    {
      title: 'Print',
      dataIndex: 'print',
      width: 120,
      render: (_: any, record: Loan) => (
        <p
          style={{
            width: 73,
            height: 34,
            borderRadius: 12,
            backgroundColor: '#DD1E2D',
            color: 'white',
            fontSize: 14,
            fontWeight: 600,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            const printLoanDetail = JSON.parse(
              JSON.stringify(record)
            ) as PrintLoan;
            setPrintSelectedLoan(printLoanDetail);
            setIsLoanPrintModalOpen(true);
          }}
        >
          Print
        </p>
      ),
    },
  ];

  const activeLoanColumns = [
    ...closedLoanColumns,
    {
      title: 'Pay',
      dataIndex: 'pay',
      width: 120,
      render: (_: any, record: Loan) => {
        const hasActionOrSales = record.jewels.some(
          (item: any) =>
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            item.closureType &&
            (item.closureType === 'Action' || item.closureType === 'Sales')
        );
        return hasActionOrSales ? (
          <p
            style={{
              width: 73,
              height: 34,
              borderRadius: 12,
              backgroundColor: '#DD1E2D',
              color: 'white',
              fontSize: 14,
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setSelectedLoan(record);
              showActionModal();
            }}
          >
            Action
          </p>
        ) : (
          <p
            style={{
              width: 73,
              height: 34,
              borderRadius: 12,
              backgroundColor: '#DD1E2D',
              color: 'white',
              fontSize: 14,
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setSelectedLoan(record);
              showPayModal();
            }}
          >
            Pay
          </p>
        );
      },
    },
  ];

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isLoanPrintModalOpen, setIsLoanPrintModalOpen] = useState(false);

  const [receiptNumber, setReceiptNumber] = useState('');
  const [paymentType, setPaymentType] = useState('');

  const [receiptTotal, setReceiptTotal] = useState(0);

  const handlePrintClose = () => {
    setIsPrintModalOpen(false);
    refetchCustomer(String(customer?.customerId));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const showPayModal = () => {
    setIsPayModalOpen(true);
  };

  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const showActionModal = () => {
    setIsActionModalOpen(true);
  };

  const handlePayClose = (
    receiptNumber: string,
    total: number,
    paymentType: string
  ) => {
    setIsPayModalOpen(false);
    if (receiptNumber !== '') {
      setReceiptNumber(receiptNumber);
      setReceiptTotal(total);
      setPaymentType(paymentType);
      setIsPrintModalOpen(true);
    } else {
      setIsPayModalOpen(false);
    }
  };

  const handlePayCloseNOOP = () => {
    setIsPayModalOpen(false);
  };

  return (
    <div className="loan__details__page">
      {/* <p className="pending__loan__title">Pending Interest: 1000</p> */}

      <div className="closed__loan__section">
        <div className="current__loan__header">
          <p className="closed__loan__title">Current Loans</p>
          <a
            className="create__loan__button"
            href={`/loan?customerId=${customer?.customerId}`}
          >
            <img className="plus__icon" src={PlusFilledWhite} /> Create Loan
          </a>
        </div>

        <Table
          dataSource={loan?.activeLoan}
          columns={activeLoanColumns}
          pagination={false}
          rowKey={(record: Loan) => record.loanNumber.toString()}
        />
      </div>
      <div className="closed__loan__section">
        <p className="closed__loan__title">Closed Loans</p>
        <Table
          dataSource={loan?.closedLoan}
          columns={closedLoanColumns}
          pagination={false}
          rowKey={(record: Loan) => record.loanNumber.toString()}
        />
      </div>
      <div className="closed__loan__section">
        <div className="current__loan__header">
          <p className="closed__loan__title">Cash disbursement pending</p>
        </div>

        <Table
          dataSource={loan?.cashPending}
          columns={closedLoanColumns}
          pagination={false}
          rowKey={(record: Loan) => record.loanNumber.toString()}
        />
      </div>
      <div className="closed__loan__section">
        <div className="current__loan__header">
          <p className="closed__loan__title">Loan Approvals Pending</p>
        </div>

        <Table
          dataSource={loan?.approvals}
          columns={nonActiveLoanColumns}
          pagination={false}
          rowKey={(record: Loan) => record.loanNumber.toString()}
        />
      </div>
      <div className="closed__loan__section">
        <div className="current__loan__header">
          <p className="closed__loan__title">Loans Rejected</p>
        </div>

        <Table
          dataSource={loan?.rejected}
          columns={nonActiveLoanColumns}
          pagination={false}
          rowKey={(record: Loan) => record.loanNumber.toString()}
        />
      </div>
      <Modal
        width={1129}
        title="Loan Details"
        open={isModalOpen}
        onCancel={handleClose}
        footer={() => <></>}
      >
        <LoanModal
          transactions={selectedLoanTransactions}
          customer={customer}
          loanDetail={selectedLoan}
        />
      </Modal>
      <Modal
        width={727}
        title={`Pay Loan - ${customer?.name} (Loan No : ${selectedLoan?.loanNumber})`}
        open={isPayModalOpen}
        onCancel={() => {
          handlePayClose('', 0, '');
        }}
        destroyOnClose
        footer={() => <></>}
      >
        <PaymentModal
          closeModal={handlePayClose}
          closeModalNOOP={handlePayCloseNOOP}
          customer={customer}
          loanDetail={selectedLoan}
        />
      </Modal>
      <Modal
        width={727}
        title={`Action Loan - ${customer?.name} (Loan No : ${selectedLoan?.loanNumber})`}
        open={isActionModalOpen}
        onCancel={() => {
          setIsActionModalOpen(false);
          setSelectedLoan(undefined);
        }}
        destroyOnClose
        footer={() => <></>}
      >
        <ActionModal
          closeModal={() => {
            setIsActionModalOpen(false);
            setSelectedLoan(undefined);
          }}
          closeModalNOOP={() => {}}
          customer={customer}
          loanDetail={selectedLoan}
        />
      </Modal>
      <Modal
        width={1020}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
            <span>Payment receipt</span>
          </div>
        }
        open={isPrintModalOpen}
        onCancel={handlePrintClose}
        footer={() => <></>}
      >
        {selectedLoan !== undefined && customer !== undefined && (
          <PrintableContent
            receiptNumber={receiptNumber}
            receiptTotal={receiptTotal}
            paymentType={paymentType}
            loanDetails={selectedLoan}
            customer={customer}
          />
        )}
      </Modal>
      <Modal
        width={1020}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
            <span>Loan summary</span>
          </div>
        }
        open={isLoanPrintModalOpen}
        onCancel={() => {
          setIsLoanPrintModalOpen(false);
        }}
        footer={() => <></>}
      >
        {printSelectedLoan !== undefined && customer !== undefined && (
          <LoanPrintableContent
            loanDetails={printSelectedLoan}
            customer={customer}
          />
        )}
      </Modal>
    </div>
  );
}
