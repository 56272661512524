import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import { createInvestment, getInvestment } from './investmentMiddleware';
import { type Investment } from './investmentTypes';

export const createInvestmentAction = async (
  dispatch: AppDispatch,
  payload: Investment
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createInvestment(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Investment added Successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error adding investment'));
    return false;
  }
};

export const getInvestmentAction = async (
  dispatch: AppDispatch,
  payload: any
) => {
  const res = await dispatch(getInvestment(payload));
  console.log(res);
};
