import React, { useState, useEffect } from 'react';
import { Spin, Table, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getInvestmentAction } from '../store/investmentActions';
import { type InvestmentResponse } from '../store/investmentTypes';
import dayjs from 'dayjs';
// import { resetDeposits } from '../store/depositReducer';

export default function InvestmentTableIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { investments }: any = useSelector<any>((state) => state?.investment);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [branch, setBranch] = useState<any>('');

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  const getInvestments = async (): Promise<void> => {
    setIsLoading(true);
    await getInvestmentAction(dispatch, { branch });
    setIsLoading(false);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (branch) {
      void getInvestments();
    }
  }, [branch]);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: InvestmentResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (_: any, record: InvestmentResponse, index: number) => (
        <p>{record.branch?.code}</p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: InvestmentResponse, index: number) => (
        <p>{record.amount}</p>
      ),
    },
    // {
    //   title: 'Offline Amount',
    //   dataIndex: 'offlineAmount',
    //   key: 'offlineAmount',
    //   render: (_: any, record: InvestmentResponse, index: number) => (
    //     <p>{record.offlineAmount}</p>
    //   ),
    // },
    {
      title: 'Invested By',
      dataIndex: 'investedBy',
      key: 'investedBy',
      render: (_: any, record: InvestmentResponse, index: number) => (
        <p>{record.investedBy}</p>
      ),
    },
    {
      title: 'Invested Date',
      dataIndex: 'investedDate',
      key: 'investedDate',
      render: (_: any, record: InvestmentResponse, index: number) => (
        <p>{dayjs(String(record.transactionDate)).format('DD-MM-YYYY')}</p>
      ),
    },
  ];

  return (
    <div className="investment__table__page">
      <Spin spinning={isLoading} fullscreen />
      <h4>Branch</h4>
      <Select
        value={branch}
        onChange={(value: string): void => {
          setBranch(value);
        }}
        style={{ width: 311 }}
        placeholder="Select branch"
        options={branchOptions}
        className="deposit__input"
      />
      {Boolean(investments.length) && (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={investments}
            columns={columns}
            rowKey={(record: InvestmentResponse) => record?._id}
          />
        </div>
      )}
    </div>
  );
}
