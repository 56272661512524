/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice } from '@reduxjs/toolkit';
import { getPrice, getOurReport, getLedger, getDashboard } from './overviewMiddleware';
import { type ourReport } from './overviewTypes';
import * as XLSX from 'xlsx';

interface InitialState {
  goldPrice: number;
  silverPrice: number;
  ourReport: ourReport[];
  dashboard: any;
}

const initialState: InitialState = {
  goldPrice: 0,
  silverPrice: 0,
  ourReport: [],
  dashboard: {}
};

type FlattenedObject = Record<string, any>;

const keysToStringify = ['loanPayments', 'pledgePayments'];

const flattenObject = (obj: any, prefix: string = ''): FlattenedObject =>
  Object.keys(obj).reduce<FlattenedObject>((acc: FlattenedObject, key: string) => {
    const pre = prefix.length ? `${prefix}.` : '';
    
    if (keysToStringify.includes(key) && Array.isArray(obj[key])) {
      acc[pre + key] = JSON.stringify(obj[key]);
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      acc[pre + key] = obj[key];
    }

    return acc;
  }, {});

const flattenLedgerData = (ledgerData:any) => {
  return ledgerData.map((entry:any) => flattenObject(entry));
};

const generateExcel = (ledgerData: any) => {

  const flattenedData = flattenLedgerData(ledgerData);
  
  const worksheet = XLSX.utils.json_to_sheet(flattenedData);

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Ledger');

  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'ledger.xlsx';
  link.click();
};

const overviewReducer = createSlice({
  name: 'overviewReducer',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPrice.fulfilled, (state, action) => {
      const goldPrice = action.payload.filter(
        (x: any) => x.name === 'Gold'
      )[0];
      const silverPrice = action.payload.filter(
        (x: any) => x.name === 'Silver'
      )[0];
      state.goldPrice = goldPrice? goldPrice.price : 0;
      state.silverPrice = silverPrice? silverPrice.price : 0;
    });

    builder.addCase(getOurReport.fulfilled, (state, action) => {
      state.ourReport = action.payload.report;
    });

    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload;
    });

    builder.addCase(getLedger.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      generateExcel(action.payload.ledger);
    });
  },
});

export default overviewReducer.reducer;
