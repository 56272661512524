import React, { useState } from 'react';
import { Table, Input, Select, Cascader } from 'antd';
import type { GetProp, CascaderProps } from 'antd';
import { type LoanItem } from '../store/loanTypes';
import PlusFilled from '../../../assets/icons/plus-filled.svg';

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  items: LoanItem[];
  updateItems: (updatedItems: LoanItem[]) => void;
  purityDetails: SelectOption[];
  metalType: SelectOption[];
}

type DefaultOptionType = GetProp<CascaderProps, 'options'>[number];

export default function ItemDescription({
  items,
  updateItems,
  purityDetails,
  metalType,
}: Props): JSX.Element {
  const [count, setCount] = useState<number>(1);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some((option) =>
      (option.label as string).toLowerCase().includes(inputValue.toLowerCase())
    );

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: LoanItem, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      width: 120,
      render: (_: any, record: LoanItem) => (
        <Cascader
          style={{ width: 150, height: 56, borderRadius: 12 }}
          options={metalType}
          onChange={(value: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            handleSelectChange(value[0], record, 'itemName');
          }}
          disabled={record.disabled}
          placeholder="Item Name"
          autoClearSearchValue
          allowClear={false}
          maxTagCount={1}
          value={[record.itemName]}
          showSearch={{ filter }}
          // onSearch={(value) => {
          //   void onSearchLoanNumber(value);
          // }}
        />
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      width: 90,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          disabled={record.disabled}
          value={record.quantity}
          onChange={(e) => {
            handleFieldChange(e, record, 'quantity');
          }}
        />
      ),
    },
    {
      title: 'Gross Wt.',
      dataIndex: 'grossWt',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          disabled={record.disabled}
          value={record.grossWt}
          onChange={(e) => {
            handleFieldChange(e, record, 'grossWt');
          }}
        />
      ),
    },
    {
      title: 'Stone Wt.',
      dataIndex: 'stoneWt',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          disabled={record.disabled}
          value={record.stoneWt}
          onChange={(e) => {
            handleFieldChange(e, record, 'stoneWt');
          }}
        />
      ),
    },
    {
      title: 'Net Wt.',
      dataIndex: 'netWt',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          disabled={record.disabled}
          value={(Number(record.grossWt) - Number(record.stoneWt)).toFixed(2)}
          onChange={(e) => {
            handleFieldChange(e, record, 'netWt');
          }}
        />
      ),
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      width: 120,
      render: (_: any, record: LoanItem) => (
        <Select
          value={record.purity}
          style={{ width: 120, height: 56, borderRadius: 12 }}
          placeholder="Purity"
          onChange={(value: string) => {
            handleSelectChange(value, record, 'purity');
          }}
          disabled={record.disabled}
          options={purityDetails}
          className="loan__select"
        />
      ),
    },
    {
      title: 'Final Amt.',
      dataIndex: 'finalAmt',
      width: 120,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          value={record.finalAmt}
          disabled
          onChange={(e) => {
            handleFieldChange(e, record, 'finalAmt');
          }}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (!record.disabled) {
              handleDelete(record);
            }
          }}
        >
          {record.disabled === true ? '' : 'Delete'}
        </p>
      ),
    },
  ];

  const totalcolumns = [
    {
      title: 'Total Quantity',
      dataIndex: 'qty',
      width: 46,
      render: () => (
        <p>
          {items.reduce((accumulator: number, currentItem: LoanItem) => {
            return (
              accumulator +
              parseFloat(Number(currentItem.quantity).toFixed(2) ?? 0)
            );
          }, 0)}
        </p>
      ),
    },
    {
      title: 'Total Gross Wt.',
      dataIndex: 'grossWt',
      width: 80,
      render: () => (
        <p>
          {items.reduce((accumulator: number, currentItem: LoanItem) => {
            return (
              accumulator +
              parseFloat(Number(currentItem.grossWt).toFixed(2) ?? 0)
            );
          }, 0)}
        </p>
      ),
    },
    {
      title: 'Total Stone Wt.',
      dataIndex: 'stoneWt',
      width: 80,
      render: () => (
        <p>
          {items.reduce((accumulator: number, currentItem: LoanItem) => {
            return (
              accumulator +
              parseFloat(Number(currentItem.stoneWt).toFixed(2) ?? 0)
            );
          }, 0)}
        </p>
      ),
    },
    {
      title: 'Total Net Wt.',
      dataIndex: 'netWt',
      width: 80,
      render: () => (
        <p>
          {items.reduce((accumulator: number, currentItem: LoanItem) => {
            return (
              accumulator +
              parseFloat(Number(currentItem.netWt).toFixed(2) ?? 0)
            );
          }, 0)}
        </p>
      ),
    },
    {
      title: 'Total Final Amt.',
      dataIndex: 'finalAmt',
      width: 120,
      render: () => (
        <p>
          {items.reduce((accumulator: number, currentItem: LoanItem) => {
            return accumulator + parseFloat(String(currentItem.finalAmt));
          }, 0)}
        </p>
      ),
    },
  ];

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    record: LoanItem,
    fieldName: keyof LoanItem
  ) => {
    const newData = [...items] as LoanItem[];
    const index = newData.findIndex((item) => record.key === item.key);

    if (index !== -1) {
      (newData[index] as any)[fieldName] = e.target.value;
      updateItems(newData);
    }
  };

  const handleSelectChange = (
    val: string,
    record: LoanItem,
    fieldName: keyof LoanItem
  ) => {
    const newData = [...items] as LoanItem[];
    const index = newData.findIndex((item) => record.key === item.key);

    if (index !== -1) {
      (newData[index] as any)[fieldName] = val;
      updateItems(newData);
    }
  };

  const handleDelete = (record: LoanItem) => {
    const newData = items.filter((item) => item.key !== record.key);
    updateItems(newData);
  };

  const handleAddNewItem = () => {
    const newItem: LoanItem = {
      key: count,
      itemName: '',
      quantity: '',
      grossWt: '',
      stoneWt: '',
      netWt: '',
      purity: '',
      finalAmt: 0,
    };
    const newData = [...items, newItem];
    setCount(count + 1);
    updateItems(newData);
  };

  return (
    <div className="loan__items__table">
      <Table
        dataSource={items}
        columns={columns}
        pagination={false}
        rowKey={(record: LoanItem) => record.key.toString()}
      />
      <div className="add__item__button">
        <img
          style={
            metalType.length === 0
              ? { opacity: 0.4, cursor: 'not-allowed' }
              : {}
          }
          src={PlusFilled}
          className="plus__filled__icon"
        />
        <p
          style={
            metalType.length === 0
              ? { opacity: 0.4, cursor: 'not-allowed' }
              : {}
          }
          className="add__new__item__text"
          onClick={metalType.length > 0 ? handleAddNewItem : () => {}}
        >
          Add New Item
        </p>
      </div>
      <Table
        style={{ marginTop: 32 }}
        dataSource={items}
        columns={totalcolumns}
        pagination={false}
        rowKey={(record: LoanItem) => record.key.toString()}
      />
    </div>
  );
}
