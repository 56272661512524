/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import React, { useState } from 'react';
import { type Loan, type Customer } from '../store/customerTypes';
import { Avatar, Table, Modal } from 'antd';
import dayjs from 'dayjs';

interface Props {
  loanDetail?: Loan;
  customer?: Customer;
  transactions?: any;
}

export default function LoanModal({
  loanDetail,
  customer,
  transactions,
}: Props): JSX.Element {
  const pledgeNumbers = new Set(
    loanDetail?.jewels
      .filter((jewel: any) => jewel.isPledged)
      .map((jewel: any) => jewel.pledgeNumber)
  );

  const jewelColumns = [
    {
      title: 'S.No',
      dataIndex: '_id',
      key: 'index',
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: 'Item Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (_: any, record: any) => <p>{record.itemType}</p>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_: any, record: any, index: number) => <p>{record.quantity}</p>,
    },
    {
      title: 'Gross Weight',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      render: (_: any, record: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <p>{parseFloat(record.grossWeight.toFixed(2))}</p>
      ),
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (_: any, record: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <p>{parseFloat(record.netWeight.toFixed(2))}</p>
      ),
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      key: 'purity',
      render: (_: any, record: any, index: number) => <p>{record.purity}</p>,
    },
    {
      title: 'Pledge Closure Type',
      dataIndex: 'closureType',
      key: 'closureType',
      render: (_: any, record: any, index: number) => (
        <p>{record.closureType}</p>
      ),
    },
    {
      title: 'Loan Closure Type',
      dataIndex: 'closureType',
      key: 'closureType',
      render: (_: any, record: any, index: number) => (
        <p>{record.loanClosureType}</p>
      ),
    },
  ];

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'transactionDate',
      key: 'index',
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>, // Format date using dayjs
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      render: (_: any, record: any) =>
        dayjs(record.transactionDate as string).format('DD/MM/YYYY'), // Format date using dayjs
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (_: any, record: any, index: number) => (
        <p>{record.interestAmount}</p>
      ),
    },
    // {
    //   title: 'Interest Offline',
    //   dataIndex: 'interestOffline',
    //   key: 'interestOffline',
    // },
    {
      title: 'Principal paid',
      dataIndex: 'principalPaid',
      key: 'principalPaid',
      render: (_: any, record: any, index: number) => <p>{record.principal}</p>,
    },
    // {
    //   title: 'Principal Offline',
    //   dataIndex: 'principalOffline',
    //   key: 'principalOffline',
    // },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (_: any, record: any) =>
        dayjs(record.createdAt as string).format('DD/MM/YYYY'), // Format date using dayjs
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
      render: (_: any, record: any, index: number) => (
        <p>{record.paymentType.toUpperCase()}</p>
      ),
    },
    {
      title: 'Payment status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any, index: number) => (
        <p>
          {record.status
            ? record.status.toUpperCase().replace('_', ' ')
            : 'Cash Received'}
        </p>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>('');

  const handleImageClick = (src: string | undefined) => {
    setImageSrc(src);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setImageSrc('');
  };

  return (
    <div className="loan__modal">
      <div className="divider" />
      <div className="profile__section">
        <Avatar
          onClick={() => {
            handleImageClick(loanDetail?.photo);
          }}
          size={64}
          icon={<img src={loanDetail?.photo} />}
        />
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleClose}
          centered
        >
          <img
            src={imageSrc}
            alt="Full Size"
            style={{ width: '100%', height: '100%' }}
          />
        </Modal>
        <div className="profile__section__details">
          <p className="customer__name">{customer?.name}</p>
          <p className="customer__id">{customer?.customerId}</p>
        </div>
      </div>
      <div className="profile__details">
        <div className="detail">
          <p className="detail__title">Loan No.</p>
          <p className="data">{loanDetail?.loanNumber}</p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Pledge No.</p>
          <p style={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from(pledgeNumbers).map((x) => (
              <p className="data" style={{ margin: 0 }} key={x}>
                {x}
              </p>
            ))}
          </p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Bank Branch</p>
          <p className="data">{loanDetail?.branch?.code}</p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Interest Rate</p>
          <p className="data">{loanDetail?.interestPercentage}</p>
        </div>
        {/* <div className="profile__divider" /> */}
        {/* <div className="detail">
          <p className="detail__title">Due Date</p>
          <p className="data">
            {dayjs(loanDetail?.dueDate as string).format('DD MMM YYYY')}
          </p>
        </div> */}
        {/* <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Created Date</p>
          <p className="data">
            {dayjs(loanDetail?.createdDate as string).format('DD MMM YYYY')}
          </p>
        </div> */}
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Start Date</p>
          <p className="data">
            {dayjs(loanDetail?.startDate as string).format('DD MMM YYYY')}
          </p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Principal</p>
          <p className="data">
            {loanDetail?.additionalAmount &&
            loanDetail.status === 'admin_approval_pending'
              ? loanDetail.principal - loanDetail.additionalAmount
              : loanDetail?.principal}
          </p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Type</p>
          <p className="data">{loanDetail?.interestType}</p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">Customer Type</p>
          <p className="data">{loanDetail?.customerType ?? 'Direct'}</p>
        </div>
        <div className="profile__divider" />
        <div className="detail">
          <p className="detail__title">
            {loanDetail?.customerType === 'takeover' ? 'Takeover' : 'Document'}{' '}
            charges
          </p>
          <p className="data">{loanDetail?.documentCharge}</p>
        </div>
        {Boolean(loanDetail?.additionalAmount) &&
          loanDetail?.status === 'admin_approval_pending' && (
            <>
              <div className="profile__divider" />
              <div className="detail">
                <p className="detail__title">Additional Amount</p>
                <p className="data">{loanDetail?.additionalAmount}</p>
              </div>
            </>
          )}
      </div>
      <div>
        <h2>Jewels</h2>
        <Table
          dataSource={loanDetail?.jewels}
          columns={jewelColumns}
          pagination={false}
        ></Table>
      </div>
      <div className="divider" />
      {Boolean(transactions.length) && (
        <div>
          <h2>Transactions</h2>
          <Table
            dataSource={transactions}
            columns={columns}
            pagination={false}
          ></Table>
        </div>
      )}
    </div>
  );
}
