import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import {
  createNewPledge, getPledgeTypes, getPledgeById, pledgeRepayment, getUnclosedPledge, getRenewalPledge, splitJewel
} from './pledgeMiddleware';
import { resetPledgeState } from './pledgeReducer';
import { type PledgePayment, type PledgeRequest } from './pledgeTypes';
// import moment from 'moment';

export const craeteNewPledgeAction = async (
  dispatch: AppDispatch,
  payload: PledgeRequest
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createNewPledge(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Pledge Created Successfully'));
  
    return res.payload.pledge.pledgeNumber;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating pledge'));
    return false;
  }
};

export const getPledgeTypesAction = async (
  dispatch: AppDispatch,
  payload: any
) => {
  const res = await dispatch(getPledgeTypes(payload));
  console.log('res.data', res);
};

export const getPledgeByIdAction = async (
  dispatch: AppDispatch,
  payload: Record<string, string>
) => {
  const res = await dispatch(getPledgeById(payload));
  console.log(res);
  if (res.meta.requestStatus !== 'fulfilled') {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error while fetching pledge'));
    dispatch(resetPledgeState());
    return false;
  }
};

export const closePledgeAction = async (
  dispatch: AppDispatch,
  payload: PledgePayment
) => {
  const res = await dispatch(pledgeRepayment(payload));
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Pledge payment Successful'));
    await getPledgeByIdAction(dispatch, {pledgeNumber: payload.pledgeNumber})
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error while closing the pledge'));
    return false;
  }
};

export const getUnclosedPledgeAction = async (
  dispatch: AppDispatch,
  payload: { branch: string}
) => {
  const res = await dispatch(getUnclosedPledge(payload));
  console.log(res);
};

export const getRenewalPledgeAction = async (
  dispatch: AppDispatch,
  payload: { branch: string}
) => {
  const res = await dispatch(getRenewalPledge(payload));
  console.log(res);
};

export const splitJewelAction = async (
  dispatch: AppDispatch,
  payload: { loanNumber: string, jewelId: string, jewels: any}
) => {
  const res = await dispatch(splitJewel(payload));
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Jewel split successful'));
  
    return true;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error splitting jewel'));
    return false;
  }
};